<template>
  <gw-dialog
    v-if="items.length"
    v-model="dialog"
    width="auto"
    @open:dialog="onOpen"
  >
    <template #activator="{ on }">
      <v-btn color="red" icon v-on="on">
        <icon-warning size="1.5em" />
      </v-btn>
    </template>

    <template #title>
      <v-card-title v-text="$t('accounting.entry.possible.issues')" />
    </template>

    <v-list>
      <v-list-item v-for="(item, index) in arItems" :key="`issue.${index}`">
        <v-list-item-title v-text="item" />
      </v-list-item>
    </v-list>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { forEach, isArray, isPlainObject, isString, toPairs } from "lodash";
import GwDialog from "@/components/common/GwDialog.vue";
import IconWarning from "@/components/icons/IconWarning.vue";

@Component({
  components: { IconWarning, GwDialog },
})
export default class IssuesTable extends Vue {
  @Prop([Array, Object]) readonly items!: string[] | Record<string, string[]>;

  dialog = false;
  arItems: string[] = [];

  // get arItems() {
  //   return map(this.items, (obItem) => {
  //     if (isString(obItem)) {
  //       return obItem;
  //     }
  //   });
  // }

  collectItems(
    arData: string | string[] | Record<string, any>,
    sItem?: string
  ) {
    if (isPlainObject(arData)) {
      const arPairs = toPairs(arData);
      forEach(arPairs, (arPairItem) => {
        forEach(arPairItem[1], (sText) => {
          this.collectItems(sText, arPairItem[0]);
        });
      });
    } else if (isArray(arData)) {
      forEach(arData, this.collectItems);
    } else if (isString(arData)) {
      if (sItem) {
        this.arItems.push(`${sItem}: ${this.$t(arData)}`);
      } else {
        this.arItems.push(this.$t(arData) as string);
      }
    }
  }

  onOpen() {
    this.collectItems(this.items);
  }
}
</script>
